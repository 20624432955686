import React, { useState, useEffect } from 'react';
import { ChevronDown, Mail, MapPin } from 'lucide-react';
import jsonp from 'jsonp';
import AnimatedTargetSelectionEngine from './targetSelectionEngine/AnimatedTargetSelectionEngine';
import './App.css';

const FullPageSection = ({ children, className }) => (
  <section className={`full-page-section ${className}`}>
    <div className="max-w-6xl mx-auto px-4 w-full">
      {children}
    </div>
  </section>
);

const ContentBox = ({ title, children, titleColor = "text-blue" }) => (
  <div className="content-box">
    <h2 className={`text-3xl font-semibold ${titleColor} mb-4`}>{title}</h2>
    {children}
  </div>
);

const NavisBioLanding = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const url = 'https://navis-bio.us13.list-manage.com/subscribe/post-json?u=6e0e8b05c119b684cd35f1238&id=0d288dae40&f_id=005f9eedf0';
    
    jsonp(`${url}&EMAIL=${encodeURIComponent(email)}`, { param: 'c' }, (err, data) => {
      if (err) {
        setStatus('Error. Please try again later.');
      } else if (data.result !== 'success') {
        setStatus(data.msg);
      } else {
        setStatus('Thank you for subscribing!');
        setEmail('');
      }
    });
  };

  return (
    <div className="font-sans text-dark-blue">
      <FullPageSection className="bg-gradient-lavender-to-white">
        <div className="relative text-center">
          <div className="relative z-10">
            <h1 className="text-6xl font-bold text-[#2656BB] mb-6 transition-all duration-500 ease-out"
                style={{ transform: `translateY(-${scrollPosition * 0.2}px)` }}>
              Navis Bio
            </h1>
            <p className="text-2xl text-[#44546A] mt-4 max-w-2xl mx-auto transition-all duration-500 ease-out"
               style={{ transform: `translateY(-${scrollPosition * 0.1}px)` }}>
              Making biomedical data easily accessible through AI.
            </p>
            <button onClick={scrollToContent} className="mt-12 text-[#2656BB] hover:text-[#92CBEA] transition-colors duration-300">
              <ChevronDown size={32} />
            </button>
          </div>
        </div>
      </FullPageSection>

      <FullPageSection className="bg-pale-blue">
        <div className="space-y-8 mt-16 mb-16">
          <ContentBox title="Our Mission">
            <p className="text-[#44546A] leading-relaxed">
            Navis Bio navigates the vast ocean of biomedical data for new and better medicines.
            
            While healthcare data is collected at unprecedented scales, researchers still struggle to access and interpret this wealth of information. By using the latest advances in AI, Navis Bio makes this knowledge easily accessible through a platform with the mission to improve and accelerate the development of new medicines.
            </p>
          </ContentBox>
          <ContentBox title="Our Offering">
            <p className="text-[#44546A] leading-relaxed mb-4">
              Every new drug begins with a crucial first step: choosing the right molecular target which the drug will act on to change the disease state. During so-called target identification and target validation, researchers must gather compelling evidence to justify the long, expensive process of developing a drug that interacts with this target in patients.
            </p>
            <p className="text-[#44546A] leading-relaxed mb-4">
              Our analysis platform accelerates this process by making it easy for researchers to rapidly interrogate different sources and types of data. By leveraging data 
            </p>
            <div className="space-y-4 mt-6 flex flex-col items-start">
            {[
              "Process literature information for rapid scouting of targets",
              "Find optimal modality-target fit",
              "Find existing experimental data to validate hypothesis",
              "Automate recurring analysis questions"
            ].map((item, index) => (
              <div key={index} className="offering-box">
                <p className="offering-text">{item}</p>
              </div>
            ))}
          </div>

          </ContentBox>
          <ContentBox title="Team">
            <p className="text-[#44546A] leading-relaxed">
              Our founders bring extensive experience from leading real-world data
              initiatives at a top 10 pharmaceuticals company and delivered AI driven insights in drug discovery, indication priorization, business development, and M&A.
            </p>
            <div className="mt-6">
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
                <div className="flex flex-col items-center">
                  <img src="/Nives.jpg" alt="Nives Rombini" className="w-32 h-32 rounded-full mb-2" />
                  <span>Nives Rombini</span>
                  <a href="https://www.linkedin.com/in/nives-rombini/" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-600 hover:underline">
                    <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                  </a>
                </div>
                <div className="flex flex-col items-center">
                  <img src="/Jonas.jpg" alt="Jonas Walheim" className="w-32 h-32 rounded-full mb-2" />
                  <span>Jonas Walheim, PhD</span>
                  <a href="https://www.linkedin.com/in/jonas-walheim-441b0284/" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-600 hover:underline">
                    <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </ContentBox>
          <FullPageSection className="bg-white">
            <ContentBox title="Identify and prioritize drug targets with AI-driven insights">
              <>
                <p className="mt-2 mb-4 text-base font-light text-[#44546A]">
                  This product is under active development. Stay tuned for updates!
                </p>
                <AnimatedTargetSelectionEngine />
              </>
            </ContentBox>
          </FullPageSection>
          <ContentBox title="Get in touch" titleColor="text-[#2656BB]">
            <p className="mb-4 text-[#44546A]">
              Interested in AI streamline target identification and validation? Reach out to us for a chat or questions.
            </p>
            <div className="flex justify-center">
              <a
                href="mailto:info@navis-bio.com"
                className="w-[30%] text-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-[#2656BB] hover:bg-[#1E4A9D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2656BB] transition duration-150 ease-in-out"
              >
                Contact Us
              </a>
            </div>
          </ContentBox>
        </div>
      </FullPageSection>

      <footer className="bg-dark-blue text-white py-16">
  <div className="max-w-6xl mx-auto px-4">
    <div className="footer-content">
      <div>
        <h2 className="text-2xl font-bold mb-4">Navis Bio</h2>
        <p>Making biomedical data easily accessible through AI.</p>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
        <div className="flex items-center mb-2">
          <Mail size={20} className="mr-2" />
          <span>info@navis-bio.com</span>
        </div>
        <div className="flex items-center">
          <MapPin size={20} className="mr-2" />
          <span>Zurich, Switzerland</span>
        </div>
      </div>
    </div>
    <div className="mt-12 pt-8 border-t border-[#91C2E9]/30 text-center">
      <p>© 2024 Navis Bio. All rights reserved.</p>
    </div>
  </div>
</footer>

    </div>
  );
};

export default NavisBioLanding;