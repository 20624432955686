import React from 'react';

const GeneTable = () => {
  const genes = [
    {
      name: 'MKNK1',
      fullName: 'MAPK Interacting Serine/Threonine Kinase 1',
      compound: 'CGP-57380, Dactolisib, BAY-885257',
      clinicalTrial: 'Phase I Dose Escalation and Expansion of Oral BAY 1143269 in Combination With Intravenous Docetaxel',
    },
    {
      name: 'KDM5B',
      fullName: 'Lysine Demethylase 5B',
      compound: 'None',
      clinicalTrial: 'None',
    },
    {
      name: 'PRMT5',
      fullName: 'Protein Arginine Methyltransferase 5',
      compound: 'CMP 5, EPZ015666, HLCL-61, MRTX-1719, MRTX-9768, PEMRAMETOSTAT, MS4322, TNG908',
      clinicalTrial: [
        "A Phase II Window of Opportunity Trial of PRMT5 Inhibitor, GSK3326595, in Early Stage Breast Cancer",
        "A Study to Investigate the Safety and Tolerability of SCR-6920 Capsule in Patients With Advanced Malignant Tumors",
        "Safety and Tolerability of TNG462 in Patients With MTAP-deleted Solid Tumors",
        "Safety and Tolerability of TNG908 in Patients With MTAP-deleted Solid Tumors",
        "A Study of AZD3470, a PRMT5 Inhibitor, in Patients With MTAP Deficient Advanced/Metastatic Solid Tumours",
        "AZD3470 as Monotherapy and in Combination With Anticancer Agents in Participants With Relapsed/Refractory Haematologic Malignancies.",
        "AMG 193 Alone or in Combination With Other Therapies in Subjects With Advanced Thoracic Tumors With Homozygous MTAP-deletion (Master Protocol)",
        "Phase 1/2 Study of MRTX1719 in Solid Tumors With MTAP Deletion"
      ],
    }
  ];

  return (
    <div className="mt-6 max-h-[400px] overflow-y-auto overflow-x-hidden">
      <table className="w-full bg-white">
        <thead>
          <tr>
            <th className="w-1/6 px-4 py-2 border sticky top-0 bg-white">Gene Symbol</th>
            <th className="w-1/6 px-4 py-2 border sticky top-0 bg-white">Full Name</th>
            <th className="w-1/3 px-4 py-2 border sticky top-0 bg-white">Compounds</th>
            <th className="w-1/3 px-4 py-2 border sticky top-0 bg-white">Clinical Trials</th>
          </tr>
        </thead>
        <tbody>
          {genes.map((gene) => (
            <tr key={gene.name}>
              <td className="px-4 py-2 border align-top">{gene.name}</td>
              <td className="px-4 py-2 border align-top">{gene.fullName}</td>
              <td className="px-4 py-2 border align-top">
                <div>{gene.compound}</div>
              </td>
              <td className="px-4 py-2 border">
                <div className="space-y-2">
                  {gene.name === 'MKNK1' ? (
                    <a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-600">{gene.clinicalTrial}</a>
                  ) : Array.isArray(gene.clinicalTrial) ? (
                    gene.clinicalTrial.map((trial, index) => (
                      <a key={index} href="#" target="_blank" rel="noopener noreferrer" className="block text-blue-600">{trial}</a>
                    ))
                  ) : (
                    gene.clinicalTrial
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeneTable;