import React, { useState, useEffect, useRef } from 'react';
import { Button, Switch } from './ui-components';
import { ChevronDown } from 'lucide-react';
import CustomSlider from './CustomSlider';
import GeneTable from './GeneTable';
import './dropdown.css';

const CustomDropdown = ({ label, value, options, isOpen, onToggle, onSelect, disabled }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!disabled) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          onToggle(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [onToggle, disabled]);

  return (
    <div className="mb-6">
      <label className="text-sm font-medium block mb-2">{label}</label>
      <div className={`dropdown-container-target ${disabled ? 'disabled' : ''}`} ref={dropdownRef}>
        <button
          className="dropdown-button-target"
          onClick={() => !disabled && onToggle(!isOpen)}
          aria-expanded={isOpen}
          disabled={disabled}
        >
          <span>{value || 'Select...'}</span>
          <ChevronDown className="dropdown-icon-target" size={20} />
        </button>
        {!disabled && isOpen && (
          <div className="dropdown-menu-target show">
            {options.map(option => (
              <div
                key={option}
                className={`dropdown-option-target ${option === value ? 'selected' : ''}`}
                onClick={() => {
                  onSelect(option);
                  onToggle(false);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const AnimatedTargetSelectionEngine = () => {
  const [scores, setScores] = useState({
    geneticEvidence: 0,
    literatureScore: 0,
    clinicalTrials: 0,
    druggability: 0,
    safetyProfile: 0,
  });

  const [iterationCount, setIterationCount] = useState(0);
  const [isProteinCoding, setIsProteinCoding] = useState(false);
  const [targetModulation, setTargetModulation] = useState('');
  const [therapeuticArea, setTherapeuticArea] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [showTherapeuticDropdown, setShowTherapeuticDropdown] = useState(false);
  const [showModulationDropdown, setShowModulationDropdown] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const finalScores = {
    geneticEvidence: 80,
    literatureScore: 70,
    clinicalTrials: 20,
    druggability: 90,
    safetyProfile: 30,
  };

  const animateSlider = (sliderName) => {
    return new Promise((resolve) => {
      const startValue = scores[sliderName];
      const endValue = finalScores[sliderName];
      const duration = 1000;
      const startTime = Date.now();

      const animate = () => {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const currentValue = startValue + (endValue - startValue) * progress;

        setScores(prev => ({ ...prev, [sliderName]: Math.round(currentValue) }));

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          resolve();
        }
      };

      animate();
    });
  };

  const runAnimation = async () => {
    if (iterationCount >= 3) return;

    setShowTherapeuticDropdown(true);
    await new Promise(resolve => setTimeout(resolve, 1000));

    setTherapeuticArea('Oncology');
    await new Promise(resolve => setTimeout(resolve, 1000));

    setShowTherapeuticDropdown(false);
    await new Promise(resolve => setTimeout(resolve, 500));

    for (const sliderName of Object.keys(finalScores)) {
      await animateSlider(sliderName);
    }

    setIsProteinCoding(true);
    await new Promise(resolve => setTimeout(resolve, 1000));

    setShowModulationDropdown(true);
    await new Promise(resolve => setTimeout(resolve, 1000));

    setTargetModulation('Inhibition');
    await new Promise(resolve => setTimeout(resolve, 1000));

    setShowModulationDropdown(false);
    await new Promise(resolve => setTimeout(resolve, 500));

    setIsButtonActive(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsButtonActive(false);

    setShowTable(true);
    await new Promise(resolve => setTimeout(resolve, 3000));

    setScores({
      geneticEvidence: 0,
      literatureScore: 0,
      clinicalTrials: 0,
      druggability: 0,
      safetyProfile: 0,
    });
    setIsProteinCoding(false);
    setTargetModulation('');
    setTherapeuticArea('');
    setShowTable(false);

    setIterationCount(prevCount => prevCount + 1);

    await new Promise(resolve => setTimeout(resolve, 2000));

    if (iterationCount < 2) {
      runAnimation();
    }
  };

  useEffect(() => {
    setIterationCount(0);
    runAnimation();
  }, []);

  const sliderData = [
    { name: 'geneticEvidence', label: 'Genetic Evidence', min: 'Limited', max: 'Strong' },
    { name: 'literatureScore', label: 'Literature Evidence', min: 'Not well known', max: 'Well established' },
    { name: 'clinicalTrials', label: 'Clinical Trials', min: 'None', max: 'Many' },
    { name: 'druggability', label: 'Druggability', min: 'Challenging', max: 'Highly druggable' },
    { name: 'safetyProfile', label: 'Safety Profile', min: 'Known concerns', max: 'No known concerns' },
  ];

  const therapeuticOptions = [
    'Hematology', 'Immunology', 'Infectious Diseases', 'Nephrology',
    'Neurology', 'Oncology', 'Ophthalmology', 'Psychiatry',
    'Rare Diseases', 'Respiratory', 'Rheumatology'
  ];

  const modulationOptions = ['Inhibition', 'Activation', 'Degradation'];

  return (
    <div className="p-6 max-w-4xl mx-auto bg-[var(--color-white)] rounded-xl shadow-md space-y-4">
      <h1 className="text-4xl font-bold mb-6 text-left" style={{ color: 'var(--color-blue)', textShadow: '1px 1px 2px rgba(38, 86, 187, 0.2)', animation: 'pulse 2s infinite' }}>Navis Target</h1>
      <CustomDropdown
        label="Therapeutic Area"
        value={therapeuticArea}
        options={therapeuticOptions}
        isOpen={showTherapeuticDropdown}
        onToggle={(isOpen) => setShowTherapeuticDropdown(isOpen)}
        onSelect={(option) => {
          setTherapeuticArea(option);
          setShowTherapeuticDropdown(false);
        }}
        disabled={!showTherapeuticDropdown}
      />

      <div className="space-y-6">
        {sliderData.map((item) => (
          <div key={item.name} className="space-y-2">
            <label className="text-sm font-medium">{item.label}</label>
            <div className="flex items-center space-x-2">
              <span className="text-xs w-28">{item.min}</span>
              <CustomSlider
                value={scores[item.name]}
                onChange={() => {}}
                min={0}
                max={100}
              />
              <span className="text-xs w-28 text-right">{item.max}</span>
            </div>
          </div>
        ))}
      </div>
      
      <div className="mt-6 space-y-4">
        <div className="flex items-center justify-between">
          <label htmlFor="protein-coding" className="text-sm font-medium">Protein-coding</label>
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name="toggle"
              id="protein-coding"
              checked={isProteinCoding}
              onChange={() => {}} // Keep this empty to prevent manual toggling
              className={`toggle-checkbox-target absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out ${isProteinCoding ? 'transform translate-x-full' : ''}`}
            />
            <label
              htmlFor="protein-coding"
              className={`toggle-label-target block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer ${isProteinCoding ? 'bg-[var(--color-blue)]' : ''}`}
            ></label>
          </div>
        </div>
      </div>

      <CustomDropdown
        label="Target Modulation"
        value={targetModulation}
        options={modulationOptions}
        isOpen={showModulationDropdown}
        onToggle={(isOpen) => setShowModulationDropdown(isOpen)}
        onSelect={(option) => {
          setTargetModulation(option);
          setShowModulationDropdown(false);
        }}
        disabled={!showModulationDropdown}
      />

      <Button 
        className="button-modern-target w-full mt-6 text-white"
        style={{ backgroundColor: isButtonActive ? 'var(--color-blue)' : 'var(--color-dark-blue)' }}
        onClick={runAnimation}
      >
        Generate Target List
      </Button>

      {showTable && <GeneTable />}
    </div>
  );
};

export default AnimatedTargetSelectionEngine;