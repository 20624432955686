import React from 'react';

const CustomSlider = ({ value, onChange, min, max }) => {
  const handleChange = (e) => {
    onChange(Number(e.target.value));
  };

  const percentage = ((value - min) / (max - min)) * 100;
  // different gray than normal palette, but looks better
  const bgGradient = `linear-gradient(to right, #EDB371 0%, #A5B4FC ${percentage}%, #e2e8f0 ${percentage}%, #e2e8f0 100%)`;

  return (
    <div className="relative w-64 h-6 bg-transparent appearance-none cursor-pointer">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className="absolute w-full h-2 bg-transparent appearance-none cursor-pointer"
        style={{
          WebkitAppearance: 'none',
          background: bgGradient,
          borderRadius: '9999px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      <style jsx>{`
        input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 16px;
          height: 16px;
          appearance: none;
          border-radius: 50%; 
          background: #6b7280;
          cursor: pointer;
        }
        input[type=range]::-moz-range-thumb {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #6b7280;
          cursor: pointer;
          border: none;
        }
      `}</style>
    </div>
  );
};

export default CustomSlider;