import React, { useState, useEffect } from 'react';
import DataVisualizer from './DataVisualizer';
import TargetPlots from './TargetPlots';
import Papa from 'papaparse';
import './abstractsViewer.css';
import FeedbackElement from './feedbackElement';

const AbstractsViewer = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('data');

  const Tab = ({ label, isActive, onClick }) => (
    <button
      className={`tab ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );

  useEffect(() => {
    console.log('Fetching data...');
    const url = 'https://navisbio.s3.eu-north-1.amazonaws.com/esmo_data_for_frontend.csv';
    console.log('Fetching from URL:', url);
    fetch(url)
      .then(response => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(csvString => {
        console.log('CSV string length:', csvString.length);
        console.log('First 100 characters of CSV:', csvString.substring(0, 100));
        const result = Papa.parse(csvString, { header: true, dynamicTyping: true });
        console.log('Parsing result:', result);
        if (result.errors.length > 0) {
          console.error('Parsing errors:', result.errors);
        }
        if (result.data.length === 0) {
          throw new Error('No data parsed from CSV');
        }
        console.log('First 5 rows of parsed data:', result.data.slice(0, 5));
        setData(result.data);
      })
      .catch(error => {
        console.error('Error fetching or parsing data:', error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="App">
      <header style={{
        backgroundColor: '#3f51b5',
        color: 'white',
        padding: '20px',
        marginBottom: '20px',
        textAlign: 'center'
      }}>
        <h1 style={{
          margin: 0,
          fontSize: '24px',
          marginBottom: '10px'
        }}>ESMO Abstracts Visualizer</h1>
        <div style={{
          fontSize: '12px',
          lineHeight: '1.4'
        }}>
          Information derived from abstracts on{' '}
          <a 
            href="https://cslide.ctimeetingtech.com/esmo2024/attendee/confcal_2/presentation" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            ESMO 2024 website
          </a>
          .<br />
          Follow link for full abstracts and original content.
        </div>
      </header>
      {data.length > 0 ? (
        <>
          <div className="tabs">
            <Tab 
              label="Abstracts Summary" 
              isActive={activeTab === 'data'} 
              onClick={() => setActiveTab('data')}
            />
            <Tab 
              label="Gene Targets" 
              isActive={activeTab === 'targets'} 
              onClick={() => setActiveTab('targets')}
            />
          </div>
          {activeTab === 'data' && <DataVisualizer data={data} />}
          {activeTab === 'targets' && <TargetPlots data={data} />}
        </>
      ) : (
        <div>Loading data...</div>
      )}
      <FeedbackElement />
    </div>
  );
};

export default AbstractsViewer;
